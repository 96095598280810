exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cancel-tsx": () => import("./../../../src/pages/cancel.tsx" /* webpackChunkName: "component---src-pages-cancel-tsx" */),
  "component---src-pages-card-jsx": () => import("./../../../src/pages/card.jsx" /* webpackChunkName: "component---src-pages-card-jsx" */),
  "component---src-pages-free-trial-card-tsx": () => import("./../../../src/pages/free-trial-card.tsx" /* webpackChunkName: "component---src-pages-free-trial-card-tsx" */),
  "component---src-pages-hidden-money-tsx": () => import("./../../../src/pages/hidden-money.tsx" /* webpackChunkName: "component---src-pages-hidden-money-tsx" */),
  "component---src-pages-landing-warn-act-tsx": () => import("./../../../src/pages/landing-warn-act.tsx" /* webpackChunkName: "component---src-pages-landing-warn-act-tsx" */),
  "component---src-pages-spam-calls-tsx": () => import("./../../../src/pages/spam-calls.tsx" /* webpackChunkName: "component---src-pages-spam-calls-tsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */)
}

